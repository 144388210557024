import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import FWGrid from "../components/Layout/Grids/image/FWGrid";
import CenteredTextBlock from "../components/CenteredTextBlock";
import LinkButton from "../components/Buttons/LinkButton";

import {
  lightboxOpenGlobal,
  lightboxContentGlobal,
  lightboxIndexGlobal,
} from "../layouts/default";
import ImageCarousel from "../components/ImageCarousel";
import { isBrowser } from "../styles/variables";

interface ProjekteProps {
  data: {
    cms: {
      inhalt: {
        title: string;
        greenLead: string;
        uri: string;
        metaBeschreibung: string;
        siteId: number;
        children: [
          {
            title: string;
            normalText: string;
            videosImagesProjekteItem: [
              {
                bild: [
                  {
                    url: string;
                    title: string;
                  }
                ];
                videoUrl: string;
              }
            ];
          }
        ];
      };
    };
  };
}

export const ProjekteQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        uri
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_projekte_Entry {
          metaBeschreibung
          greenLead
          alleProjekteLink
        }
        children(limit: 5) {
          title
          ... on CMS_projekteItem_Entry {
            normalText
            videosImagesProjekteItem {
              ... on CMS_image2_Entry {
                bild {
                  url
                  title
                }
              }
              ... on CMS_youtubeVideo2_Entry {
                videoUrl
              }
            }
          }
        }
      }
      lokalisierung: entry(siteId: [$siteId], type: "metaDatenLokalisierung") {
        ... on CMS_metaDatenLokalisierung_Entry {
          weitereProjekteAnzeigen
        }
      }
    }
  }
`;

const Projekte: React.FC<ProjekteProps> = ({ data }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();
  const [lightboxContent, setLightboxContent] = lightboxContentGlobal();
  const [lightboxIndex, setLightboxIndex] = lightboxIndexGlobal();

  const getContent = (obj, index) => {
    if (obj.videosImagesProjekteItem.length === 1) {
      return (
        <div key={index}>
          <CenteredTextBlock
            lessBottomSpacing
            inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
          />
          {obj.videosImagesProjekteItem[0].bild ? (
            <FWGrid
              image={obj.videosImagesProjekteItem[0].bild[0]}
              lightboxInhalt={obj.videosImagesProjekteItem}
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
            />
          ) : obj.videosImagesProjekteItem[0].videoUrl ? (
            <FWGrid
              video={obj.videosImagesProjekteItem[0].videoUrl}
              lightboxInhalt={obj.videosImagesProjekteItem}
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else if (obj.videosImagesProjekteItem.length >= 2) {
      return (
        <div key={index}>
          <CenteredTextBlock
            lessBottomSpacing
            inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
          />
          {isBrowser ? (
            <ImageCarousel
              inhalt={obj.videosImagesProjekteItem}
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
              setLightboxIndex={setLightboxIndex}
              index={index}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else if (obj.normalText) {
      return (
        <div key={index}>
          <CenteredTextBlock
            lessBottomSpacing
            inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
            key={index}
          />
        </div>
      );
    }
  };
  return (
    <DefaultLayout
      siteId={data.cms.inhalt.siteId}
      title={data.cms.inhalt.title}
      //links={data.cms.inhalt.localized}
      beschreibung={
        data.cms.inhalt.metaBeschreibung
          ? data.cms.inhalt.metaBeschreibung
          : false
      }
    >
      <HeaderTitle inhalt={data.cms.inhalt.title} />
      <GreenLead inhalt={data.cms.inhalt.greenLead} />
      {data.cms.inhalt.children.map((obj, index) => {
        return getContent(obj, index);
      })}
      {data.cms.inhalt.alleProjekteLink ? (
        <LinkButton
          link={"/" + data.cms.inhalt.uri + "/all"}
          text={data.cms.lokalisierung.weitereProjekteAnzeigen}
          styleObj={{ marginTop: 35 }}
        />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default Projekte;
